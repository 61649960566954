import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { API_URL } from 'sow/constants/api';

import * as adminRequestActions from 'sow/actions/adminRequest';

import TodoListFilters from 'sow/components/organisms/TodoListFilters';
import Spinner from 'sow/components/atoms/Spinner';

const paramsForURL = filters => queryString.stringify(filters, { arrayFormat: 'comma' });

const paramsForExport = filters =>
  queryString.stringify(filters, { arrayFormat: 'bracket' });

const cleanFilters = filters => {
  let cleanedFilters = {};
  for (let key in filters) {
    switch (R.type(filters[key])) {
      case 'Object':
        const cleanedObj = cleanRangeFilter(filters[key]);
        if (R.isEmpty(cleanedObj)) {
          break;
        } else {
          cleanedFilters = { ...cleanedFilters, ...cleanedObj };
        }
        break;
      case 'String':
      case 'Number':
      case 'Boolean':
        if (R.isEmpty(filters[key])) {
          break;
        } else {
          cleanedFilters[key] = filters[key];
          break;
        }
      case 'Array':
        cleanedFilters[key] = cleanMultiFilter(filters[key]);
        break;
      default:
        break;
    }
  }
  return cleanedFilters;
};

const cleanRangeFilter = rangeObj => {
  const cleanObj = {};
  for (let key in rangeObj) {
    switch (R.isEmpty(rangeObj[key])) {
      case true:
        break;
      default:
        cleanObj[key] = rangeObj[key];
        break;
    }
  }
  return cleanObj;
};

const cleanMultiFilter = list =>
  R.map(filter => {
    if (R.has('value', filter)) {
      return filter.value;
    } else {
      return filter;
    }
  }, list);

const emptyInitialValues = {
  anniversary_date_range: {
    anniversary_date_start: '',
    anniversary_date_end: '',
  },
};

const mapStateToProps = (state, props) => ({
  isFetching: R.path(['adminRequests', 'todoListFilters', 'isFetching'], state),
  filterOptions: R.path(['adminRequests', 'todoListFilters', 'filterOptions'], state),
  hasResults: R.pathOr([], ['adminRequests', 'todoList', 'list'], state).length > 0,
});

const mapDispatchToProps = {
  fetchOptions: adminRequestActions.fetchRequestOptions,
  fetchTodos: adminRequestActions.fetchUserOrgRequests,
};

class TodoListFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: R.clone(emptyInitialValues),
    };
  }

  componentDidMount() {
    this.props.fetchOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching != this.props.isFetching) {
      const urlFilters = queryString.parse(this.props.location.search, {
        arrayFormat: 'comma',
      });

      this.setInitialValues(urlFilters);
    }
  }

  setInitialValues(urlFilters) {
    const { initialValues } = this.state;

    for (let key in urlFilters) {
      switch (key) {
        case 'category':
          if (R.type(urlFilters[key]) === 'String') {
            initialValues[key] = [urlFilters[key]];
            break;
          } else {
            initialValues[key] = urlFilters[key];
          }
        case 'anniversary_date_start':
        case 'anniversary_date_end':
          initialValues.anniversary_date_range[key] = urlFilters[key];
          break;
        default:
          initialValues[key] = urlFilters[key];
          break;
      }
    }
    this.setState({ initialValues: { ...initialValues } });
    if (!R.isEmpty(urlFilters)) {
      const cleanedFilters = cleanFilters(initialValues);
      this.props.fetchTodos(cleanedFilters);
    } else {
      this.props.fetchTodos();
    }
  }

  handleSearch(values, formikBag) {
    const cleanedFilters = cleanFilters(values);
    const params = paramsForURL(cleanedFilters);
    if (params) {
      history.pushState(null, null, `?${params}`);
    }

    this.props.fetchTodos(cleanedFilters);
  }

  resetFilters(values, formikBag) {
    this.setState({ initialValues: { ...emptyInitialValues } }, () => {
      history.pushState(null, null, location.pathname);
      formikBag.resetForm({ ...emptyInitialValues });
    });

    this.props.fetchTodos();
  }

  exportList(values) {
    const cleanedFilters = cleanFilters(values);
    const params = paramsForExport(cleanedFilters);
    window.open(`${API_URL}/v1/user/requests/export?${params}`, '_blank');
  }

  render() {
    if (this.props.isFetching) return <Spinner />;
    return (
      <TodoListFilters
        initialValues={this.state.initialValues}
        filterOptions={this.props.filterOptions}
        handleSearch={this.handleSearch.bind(this)}
        resetFilters={this.resetFilters.bind(this)}
        exportList={this.exportList.bind(this)}
        hasResults={this.props.hasResults}
      />
    );
  }
}

TodoListFiltersContainer.propTypes = {
  isFetching: PropTypes.bool,
  filterOptions: PropTypes.object,
  hasResults: PropTypes.bool,
};

TodoListFiltersContainer.defaultProps = {
  isFetching: true,
  filterOptions: {},
  hasResults: false,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoListFiltersContainer);
