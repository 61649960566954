import {
  getRequestsForApplication,
  getActivityRequests,
  getLocationRequests,
  createRequest,
  clearRequest,
  getUserOrgRequests,
  getUserOrgRequestOptions,
} from 'sow/services/adminRequest';

const mark = action => `soworganic/admin_requests/${action}`;

export const ADMIN_REQUESTS_LIST = mark('ADMIN_REQUESTS_LIST');
export const ADMIN_REQUESTS_LIST_SUCCESS = mark('ADMIN_REQUESTS_LIST_SUCCESS');
export const ADMIN_REQUESTS_LIST_FAILED = mark('ADMIN_REQUESTS_LIST_FAILED');

export const ACTIVITY_REQUESTS_LIST = mark('ACTIVITY_REQUESTS_LIST');
export const ACTIVITY_REQUESTS_LIST_SUCCESS = mark('ACTIVITY_REQUESTS_LIST_SUCCESS');
export const ACTIVITY_REQUESTS_LIST_FAILED = mark('ACTIVITY_REQUESTS_LIST_FAILED');

export const LOCATION_REQUESTS_LIST = mark('LOCATION_REQUESTS_LIST');
export const LOCATION_REQUESTS_LIST_SUCCESS = mark('LOCATION_REQUESTS_LIST_SUCCESS');
export const LOCATION_REQUESTS_LIST_FAILED = mark('LOCATION_REQUESTS_LIST_FAILED');

export const ADMIN_REQUEST_CREATE = mark('ADMIN_REQUEST_CREATE');
export const ADMIN_REQUEST_CREATE_SUCCESS = mark('ADMIN_REQUEST_CREATE_SUCCESS');
export const ADMIN_REQUEST_CREATE_FAILED = mark('ADMIN_REQUEST_CREATE_FAILED');

export const ADMIN_REQUEST_CLEAR = mark('ADMIN_REQUEST_CLEAR');
export const ADMIN_REQUEST_CLEAR_SUCCESS = mark('ADMIN_REQUEST_CLEAR_SUCCESS');
export const ADMIN_REQUEST_CLEAR_FAILED = mark('ADMIN_REQUEST_CLEAR_FAILED');

export const FETCH_USER_REQUESTS = mark('FETCH_USER_REQUESTS');
export const FETCH_USER_REQUESTS_SUCCESS = mark('FETCH_USER_REQUESTS_SUCCESS');

export const FETCH_REQUEST_OPTIONS = mark('FETCH_REQUEST_OPTIONS');
export const FETCH_REQUEST_OPTIONS_SUCCESS = mark('FETCH_REQUEST_OPTIONS_SUCCESS');
export const FETCH_REQUEST_OPTIONS_FAILED = mark('FETCH_REQUEST_OPTIONS_FAILED');

export const fetchAdminRequests = (orgId, planAppId) => dispatch => {
  dispatch({
    type: ADMIN_REQUESTS_LIST,
    data: { orgId: orgId, planAppId: planAppId },
  });

  getRequestsForApplication(orgId, planAppId)
    .done(res => {
      dispatch({
        type: ADMIN_REQUESTS_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      dispatch({ type: ADMIN_REQUESTS_LIST_FAILED });
    });
};

export const fetchActivityRequests = (orgId, planAppId) => dispatch => {
  dispatch({
    type: ACTIVITY_REQUESTS_LIST,
    data: { orgId: orgId, planAppId: planAppId },
  });

  getActivityRequests(orgId, planAppId)
    .done(res => {
      dispatch({
        type: ACTIVITY_REQUESTS_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      dispatch({ type: ACTIVITY_REQUESTS_LIST_FAILED });
    });
};

export const fetchLocationRequests = (orgId, planAppId) => dispatch => {
  dispatch({
    type: LOCATION_REQUESTS_LIST,
    data: { orgId: orgId, planAppId: planAppId },
  });

  getLocationRequests(orgId, planAppId)
    .done(res => {
      dispatch({
        type: LOCATION_REQUESTS_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      dispatch({ type: LOCATION_REQUESTS_LIST_FAILED });
    });
};

export const createAdminRequest = (orgId, planAppId, body) => dispatch => {
  dispatch({ type: ADMIN_REQUEST_CREATE });

  createRequest(orgId, planAppId, body)
    .done(res => {
      dispatch({
        type: ADMIN_REQUEST_CREATE_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.log('Error creating admin request:', err.responseJSON);
      dispatch({ type: ADMIN_REQUEST_CREATE_FAILED });
    });
};

export const clearAdminRequest = (orgId, planAppId, requestId) => dispatch => {
  dispatch({ type: ADMIN_REQUEST_CLEAR });

  clearRequest(orgId, planAppId, requestId)
    .done(res => {
      dispatch({
        type: ADMIN_REQUEST_CLEAR_SUCCESS,
        data: requestId,
      });
    })
    .fail(err => {
      console.log('Error clearing admin request:', err.responseJSON);
      dispatch({ type: ADMIN_REQUEST_CLEAR_FAILED });
    });
};

export const fetchUserOrgRequests = params => dispatch => {
  dispatch({ type: FETCH_USER_REQUESTS });

  getUserOrgRequests(params).done(({ data }) => {
    dispatch({ type: FETCH_USER_REQUESTS_SUCCESS, data });
  });
};

export const fetchRequestOptions = () => dispatch => {
  dispatch({ type: FETCH_REQUEST_OPTIONS });

  getUserOrgRequestOptions().done(({ data }) => {
    dispatch({ type: FETCH_REQUEST_OPTIONS_SUCCESS, data });
  });
};
