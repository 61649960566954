import React from 'react';

import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { planAppStateText } from 'sow/constants/planApp';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const TodoListTable = ({ todoList }) => (
  <Widget>
    <WidgetHeading>To-do List</WidgetHeading>
    <WidgetBody>
      <ImprovedDataTable
        selector={'.todoListTable'}
        tableDef={{
          pageLength: 50,
          destroy: true,
          searching: false,
          data: todoList,
          deferRender: true,
          order: [],
          columns: [
            { data: 'org' },
            { data: 'noncompliant' },
            { data: 'anniversary_date' },
            { data: 'app_state' },
            { data: 'organization_types' },
            { data: 'worksheet' },
            { data: 'question' },
          ],
          columnDefs: [
            {
              targets: 0,
              render: data =>
                `<a href="/org/${data.id}" target="_blank">${data.name}</a>`,
            },
            {
              targets: 1,
              render: data => {
                if (data) return 'Yes';
                return 'No';
              },
            },
            {
              type: 'date',
              targets: [2],
              render: data => {
                if (data) {
                  return monthDayYearDateFormat(data);
                }
                return null;
              },
            },
            {
              targets: 3,
              render: data => {
                if (!data) {
                  return `<p>Not Started</p>`;
                } else {
                  return `<p>${planAppStateText[data]}</p>`;
                }
              },
            },
            {
              targets: 5,
              render: data => {
                if (data.location_id)
                  return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}" target="_blank">${data.name}</a>`;
                return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}" target="_blank">${data.name}</a>`;
              },
            },
            {
              targets: 6,
              render: data => {
                if (data.location_id) {
                  if (data.matrix_row_id)
                    return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}#pa-wsmr--${data.matrix_row_id}" target="_blank">${data.name}</a>`;
                  return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}#pa-wsq--${data.question_id}" target="_blank">${data.name}</a>`;
                }
                if (data.question_id) {
                  if (data.matrix_row_id)
                    return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}#pa-wsmr--${data.matrix_row_id}" target="_blank">${data.name}</a>`;
                  return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}#pa-wsq--${data.question_id}" target="_blank">${data.name}</a>`;
                }
                return 'All worksheet questions have been marked to-do.';
              },
            },
          ],
        }}
      >
        <Table
          className={'todoListTable'}
          bordered
          striped
          condensed
          head={
            <TableRow>
              <TableCell heading textCenter>
                Organization
              </TableCell>
              <TableCell heading textCenter>
                Noncompliant
              </TableCell>
              <TableCell heading textCenter>
                Anniversary Date
              </TableCell>
              <TableCell heading textCenter>
                OSP Status
              </TableCell>
              <TableCell heading textCenter>
                Category
              </TableCell>
              <TableCell heading textCenter>
                Worksheet
              </TableCell>
              <TableCell heading textCenter>
                Question
              </TableCell>
            </TableRow>
          }
          style={{ width: '100%' }}
        >
          <TableRow></TableRow>
        </Table>
      </ImprovedDataTable>
    </WidgetBody>
  </Widget>
);

export default TodoListTable;
