import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { planAppStateText, planAppStates } from 'sow/constants/planApp';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikDateRange from 'sow/components/molecules/FormikDateRange';
import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Strong from 'sow/components/atoms/Strong';

const formSchema = Yup.object({
  anniversary_date_range: Yup.object({
    anniversary_date_start: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
    anniversary_date_end: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
  }),
});

const ospStatusOptions = [];

for (const property in planAppStates) {
  if (property !== 'NONCOMPLIANCE')
    ospStatusOptions.push({
      value: planAppStates[property],
      label: planAppStateText[planAppStates[property]],
    });
}

const TodoListFilters = ({
  initialValues,
  filterOptions,
  handleSearch,
  resetFilters,
  exportList,
  hasResults,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={formSchema}
    onSubmit={handleSearch}
    onReset={resetFilters}
  >
    {({ values, errors, handleSubmit, handleReset }) => (
      <Widget className="search-filter-block">
        <WidgetHeading>
          To-do List Search Filter
          <Block pullRight>
            {hasResults && (
              <Button onClick={() => exportList(values)} primary sm>
                <Glyphicon glyph="export" /> Export
              </Button>
            )}
          </Block>
        </WidgetHeading>
        <WidgetBody className="filter-body">
          <Form onSubmit={handleSubmit}>
            <Row className="search-filter-row">
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Organization"
                  placeholder=""
                  name={'org_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={filterOptions.org_options}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Noncompliant"
                  placeholder=""
                  name={'noncompliant'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Anniversary Date"
                  placeholder=""
                  name={'anniversary_date_range'}
                  dateFormat="MM/DD/YYYY"
                  startDateID={'anniversary_date_start'}
                  endDateID={'anniversary_date_end'}
                  component={FormikField}
                  type={FormikDateRange}
                />
                {R.has('anniversary_date_range', errors) && (
                  <Block textDanger>
                    <Strong>Error:</Strong>{' '}
                    {'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)'}
                  </Block>
                )}
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Status"
                  placeholder=""
                  name={'app_state'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={ospStatusOptions}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Category"
                  placeholder=""
                  name={'category'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  multi={true}
                  options={filterOptions.organization_type_options}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Worksheet"
                  placeholder=""
                  name={'worksheet_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={filterOptions.osp_definition_worksheet_options}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Question"
                  placeholder=""
                  name={'question_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={
                    R.pathOr(null, ['worksheet_id'], values)
                      ? filterOptions.osp_definition_worksheet_question_options[
                          values.worksheet_id
                        ]
                      : filterOptions.osp_definition_worksheet_question_options.all
                  }
                />
              </Column>
            </Row>
            <Row className="search-filter-row">
              <Column className="search-filter-column button-column" xs={12} md={4}>
                <Row className="search-filter-row">
                  <Column className="search-filter-column button-column" xs={6}>
                    <Button
                      className="filter-command-button search-button"
                      type="submit"
                      disabled={!R.isEmpty(errors)}
                      primary
                    >
                      Search
                    </Button>
                  </Column>
                  <Column className="search-filter-column button-column" xs={6}>
                    <Button
                      className="filter-command-button reset-button"
                      onClick={handleReset}
                      primary
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        </WidgetBody>
      </Widget>
    )}
  </Formik>
);

export default TodoListFilters;
