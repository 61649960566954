import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TodoListTable from 'sow/components/organisms/TodoListTable';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = state => ({
  isFetchingOptions: R.path(['adminRequests', 'todoListFilters', 'isFetching'], state),
  isFetchingList: R.path(['adminRequests', 'todoList', 'isFetching'], state),
  todoList: R.path(['adminRequests', 'todoList', 'list'], state),
});

const mapDispatchToProps = {};

class TodoListTableContainer extends Component {
  render() {
    if (this.props.isFetchingOptions) return null;
    if (this.props.isFetchingList) return <Spinner />;
    return <TodoListTable todoList={this.props.todoList} />;
  }
}

TodoListTableContainer.propTypes = {
  isFetchingOptions: PropTypes.bool,
  isFetchingList: PropTypes.bool,
  todoList: PropTypes.array,
};

TodoListTableContainer.defaultProps = {
  isFetchingOptions: true,
  isFetchingList: true,
  todoList: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoListTableContainer);
