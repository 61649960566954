import React from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Redirect, Route } from 'react-router-dom';

import SecuredRoute from './SecuredRoute';

import DashboardRedirect from 'sow/containers/DashboardRedirect';
import LogoutPage from 'sow/containers/pages/LogoutPage';
import LoginPage from 'sow/containers/pages/LoginPage';
import EcertLoginPage from 'sow/containers/pages/EcertLoginPage';
import RequestNewPasswordPage from 'sow/components/old/auth/passwordReset/RequestNewPassword';
import RequestNewPasswordDonePage from 'sow/components/old/auth/passwordReset/RequestNewPasswordDone';
import ResetPasswordPage from 'sow/components/old/auth/passwordReset/ResetPassword';
import RegisterPage from 'sow/components/old/auth/register/Register';
import RegisterDonePage from 'sow/components/pages/RegisterDonePage';
import ValidateMailPage from 'sow/containers/pages/ValidateMailPage';
import AcaRegister from 'sow/components/old/auth/register/AcaRegister';
import InspectorRegister from 'sow/components/old/auth/register/InspectorRegister';
import AdminDashboardPage from 'sow/components/pages/AdminDashboardPage';
import CreateAcaPage from 'sow/containers/pages/CreateAcaPage';
import UserLookupPage from 'sow/components/pages/UserLookupPage';
import SystemAdminObjectPage from 'sow/components/pages/SystemAdminObjectPage';
import SystemAdminItemPage from 'sow/components/pages/SystemAdminItemPage';
import CertificationTypesListPage from 'sow/components/pages/CertificationTypesListPage';
import CertificationTypesEditPage from 'sow/components/pages/CertificationTypesEditPage';
import ConsultantCompaniesListPage from 'sow/components/pages/ConsultantCompaniesListPage';
import ConsultantCompaniesEditPage from 'sow/components/pages/ConsultantCompaniesEditPage';
import OrganizationTypeListPage from 'sow/components/pages/OrganizationTypeListPage';
import OrganizationTypeEditPage from 'sow/components/pages/OrganizationTypeEditPage';
import AnnouncementTypeListPage from 'sow/components/pages/AnnouncementTypeListPage';
import AnnouncementTypeEditPage from 'sow/components/pages/AnnouncementTypeEditPage';
import AnnouncementsListPage from 'sow/components/pages/AnnouncementsListPage';
import AnnouncementsEditPage from 'sow/components/pages/AnnouncementsEditPage';
import AnnouncementHistoryPage from 'sow/components/pages/AnnouncementHistoryPage';
import AccessTypeListPage from 'sow/components/pages/AccessTypeListPage';
import AccessTypeEditPage from 'sow/components/pages/AccessTypeEditPage';
import InspectionYearListPage from 'sow/components/pages/InspectionYearListPage';
import InspectionYearEditPage from 'sow/components/pages/InspectionYearEditPage';
import PriorityTypeListPage from 'sow/components/pages/PriorityTypeListPage';
import PriorityTypeEditPage from 'sow/components/pages/PriorityTypeEditPage';
import ItemListPage from 'sow/components/pages/ItemListPage';
import ItemEditPage from 'sow/components/pages/ItemEditPage';
import ItemTypeListPage from 'sow/components/pages/ItemTypeListPage';
import ItemTypeEditPage from 'sow/components/pages/ItemTypeEditPage';
import ItemSubtypeListPage from 'sow/components/pages/ItemSubtypeListPage';
import ItemSubtypeEditPage from 'sow/components/pages/ItemSubtypeEditPage';
import ItemVersionListPage from 'sow/components/pages/ItemVersionListPage';
import ItemVersionInfoEditPage from 'sow/components/pages/ItemVersionInfoEditPage';
import ItemVersionCreatorPage from 'sow/components/pages/ItemVersionCreatorPage';
import ItemVersionPicklistPage from 'sow/components/pages/ItemVersionPicklistPage';
import ItemVersionHistoryPage from 'sow/components/pages/ItemVersionHistoryPage';
import ItemAttributeTypeListPage from 'sow/components/pages/ItemAttributeTypeListPage';
import ItemAttributeTypeEditPage from 'sow/components/pages/ItemAttributeTypeEditPage';
import ItemSectionTypeListPage from 'sow/components/pages/ItemSectionTypeListPage';
import ItemSectionTypeEditPage from 'sow/components/pages/ItemSectionTypeEditPage';
import ActionGroupListPage from 'sow/components/pages/ActionGroupListPage';
import ActionGroupEditPage from 'sow/components/pages/ActionGroupEditPage';
import UserManagementUserLookupPage from 'sow/components/pages/UserManagementUserLookupPage';
import UserManagementEditUserPage from 'sow/components/pages/UserManagementEditUserPage';
import UserManagementUserHistoryPage from 'sow/components/pages/UserManagementUserHistoryPage';
import AdminProductSearchPage from 'sow/components/pages/AdminProductSearchPage';
import OrgProductSearchPage from 'sow/components/pages/OrgProductSearchPage';
import AcaDashboardPage from 'sow/containers/pages/AcaDashboardPage';
import AcaActivityDashboardPage from 'sow/containers/pages/AcaActivityDashboardPage';
import AcaPlanDashboardPage from 'sow/containers/pages/AcaPlanDashboardPage';
import OspDefinition from 'sow/containers/aca/OspDefinition';
import AcaOrgListPage from 'sow/containers/pages/AcaOrgListPage';
import AcaOrgSearchPage from 'sow/components/pages/AcaOrgSearchPage';
import AcaSettingsGeneralPage from 'sow/containers/pages/AcaSettingsGeneralPage';
import AcaReportsPage from 'sow/containers/pages/AcaReportsPage';
import InspectionReportAllPage from 'sow/components/pages/InspectionReportAllPage';
import InspectionReportUnannouncedPage from 'sow/components/pages/InspectionReportUnannouncedPage';
import LocationReportPage from 'sow/components/pages/LocationReportPage';
import AcaReportPage from 'sow/containers/pages/AcaReportPage';
import AcaSettingsRegisterPage from 'sow/containers/pages/AcaSettingsRegisterPage';
import AcaSettingsRegisterInspectorPage from 'sow/containers/pages/AcaSettingsRegisterInspectorPage';
import AcaSettingsStaffPage from 'sow/containers/pages/AcaSettingsStaffPage';
import AcaSettingsWorkflowPage from 'sow/components/pages/AcaSettingsWorkflowPage';
import AcaCertificationDocsPage from 'sow/containers/pages/AcaCertificationDocsPage';
import InspectorDashboardPage from 'sow/components/pages/InspectorDashboardPage';
import ClientDashboardPage from 'sow/containers/pages/ClientDashboardPage';
import TodoListPage from 'sow/components/pages/TodoListPage';
import ErrorUnauthorizedPage from 'sow/components/pages/ErrorUnauthorizedPage';
import ErrorForbiddenPage from 'sow/components/pages/ErrorForbiddenPage';
import ErrorNotFoundPage from 'sow/components/pages/ErrorNotFoundPage';
import ErrorInactiveAccountPage from 'sow/components/pages/ErrorInactiveAccountPage';
import ProfileMyProfilePage from 'sow/containers/pages/ProfileMyProfilePage';
import OrgDashboardPage from 'sow/containers/pages/OrgDashboardPage';
import OrgWorkflowDashboardPage from 'sow/containers/pages/OrgWorkflowDashboardPage';
import SpecialistDashboardPage from 'sow/components/pages/SpecialistDashboardPage';
import OrgHistoricalPlansPage from 'sow/containers/pages/OrgHistoricalPlansPage';
import LegacyLocationsPage from 'sow/containers/pages/OrgLegacyLocationsPage';
import OrgSettingsGeneralTabPage from 'sow/components/pages/OrgSettingsGeneralTabPage';
import OrgSettingsAcaTabPage from 'sow/components/pages/OrgSettingsAcaTabPage';
import OrgSettingsInspectorsTabPage from 'sow/components/pages/OrgSettingsInspectorsTabPage';
import OrgSettingsCertificationsTabPage from 'sow/components/pages/OrgSettingsCertificationsTabPage';
import OrgSettingsMigratePage from 'sow/components/pages/OrgSettingsMigratePage';
import OrgSettingsHistoryTabPage from 'sow/components/pages/OrgSettingsHistoryTabPage';
import OrgPlanAppChecklistPage from 'sow/containers/pages/OrgPlanAppChecklistPage';
import OrgPlanAppWorksheetOverviewPage from 'sow/containers/pages/OrgPlanAppWorksheetOverviewPage';
import OrgPlanAppWorksheetPage from 'sow/containers/pages/OrgPlanAppWorksheetPage';
import OrgPlanAppLocationOverviewPage from 'sow/containers/pages/OrgPlanAppLocationOverviewPage';
import OrgPlanAppLocationPage from 'sow/containers/pages/OrgPlanAppLocationPage';
import OrgCertificationDocsPage from 'sow/containers/pages/OrgCertificationDocsPage';
import OrgPlanAppExportPage from 'sow/components/pages/OrgPlanAppExportPage';
import OrgAttachmentListPage from 'sow/containers/attachment/OrgAttachmentList';
import OrgAttachmentOspListPage from 'sow/containers/attachment/OrgAttachmentOspList';
import OrgSetupPage from 'sow/containers/pages/OrgSetupPage';
import OrgContactsPage from 'sow/components/pages/OrgContactsPage';
import OrgContactsEditPage from 'sow/components/pages/OrgContactsEditPage';
import ImpersonateUserTransitionPage from 'sow/components/pages/ImpersonateUserTransitionPage';
import OrgLocationsPage from 'sow/components/pages/OrgLocationsPage';

const Routes = () => (
  <Switch>
    <SecuredRoute path="/" component={DashboardRedirect} exact />

    {/* Authentication */}
    <Route path="/auth/logout" component={LogoutPage} exact />
    <Route path="/auth/login" component={LoginPage} exact />
    <Route path="/auth/ecert_login" component={EcertLoginPage} exact />
    <Route path="/auth/request-new-password" component={RequestNewPasswordPage} exact />
    <Route
      path="/auth/request-new-password/done"
      component={RequestNewPasswordDonePage}
      exact
    />
    <Route
      path="/auth/reset-password/:resetPasswordHash"
      component={ResetPasswordPage}
      exact
    />

    {/* Registration */}
    <SecuredRoute
      path="/auth/register"
      component={RegisterPage}
      allowedRoles={['admin', 'workflow_manager', 'certification_specialist']}
      exact
    />
    <SecuredRoute
      path="/auth/register/done"
      component={RegisterDonePage}
      allowedRoles={['admin', 'workflow_manager', 'certification_specialist']}
      exact
    />
    <SecuredRoute
      path="/auth/register/validate-mail/:emailHash"
      component={ValidateMailPage}
      allowedRoles={['admin', 'workflow_manager', 'certification_specialist']}
      exact
    />
    <Route path="/-/:acaSlug" component={AcaRegister} exact />
    <Redirect from="/ccof" to="/-/ccof" exact />
    <SecuredRoute
      path="/inspector"
      component={InspectorRegister}
      allowedRoles={['admin', 'workflow_manager', 'certification_specialist']}
      exact
    />

    {/* User Profile */}
    <SecuredRoute path="/profile" component={ProfileMyProfilePage} />

    {/* Administration */}
    <SecuredRoute
      path="/admin"
      component={AdminDashboardPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/create-aca"
      component={CreateAcaPage}
      allowedRoles={['admin']}
      exact
    />
    {/* Old User Lookup Page */}
    {/* <SecuredRoute
      path="/admin/user-lookup"
      component={UserLookupPage}
      allowedRoles={['admin']}
      exact
    /> */}
    <SecuredRoute
      path="/admin/user-lookup"
      component={UserManagementUserLookupPage}
      allowedRoles={['admin']}
      exact
    />
    <Redirect from="/admin/system-admin" to="/admin/system-admin/object" exact />
    <SecuredRoute
      path="/admin/system-admin/object"
      component={SystemAdminObjectPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item"
      component={SystemAdminItemPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/certification-types"
      component={CertificationTypesListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/certification-types/:id"
      component={CertificationTypesEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/consultant-companies"
      component={ConsultantCompaniesListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/consultant-companies/:id"
      component={ConsultantCompaniesEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/organization-type"
      component={OrganizationTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/organization-type/:id"
      component={OrganizationTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/announcement-type"
      component={AnnouncementTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/announcement-type/:id"
      component={AnnouncementTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/announcements"
      component={AnnouncementsListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/announcements/:id"
      component={AnnouncementsEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/announcements/:id/history"
      component={AnnouncementHistoryPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/access-type"
      component={AccessTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/access-type/:id"
      component={AccessTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/inspection-year"
      component={InspectionYearListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/inspection-year/:id"
      component={InspectionYearEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/priority-type"
      component={PriorityTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/priority-type/:id"
      component={PriorityTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item"
      component={ItemListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item/:id"
      component={ItemEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-type"
      component={ItemTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-type/:id"
      component={ItemTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-subtype"
      component={ItemSubtypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-subtype/:id"
      component={ItemSubtypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-version"
      component={ItemVersionListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-version/:id"
      component={ItemVersionInfoEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-version/:id/creator"
      component={ItemVersionCreatorPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-version/:id/picklist"
      component={ItemVersionPicklistPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-version/:id/history"
      component={ItemVersionHistoryPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-attribute-type"
      component={ItemAttributeTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-attribute-type/:id"
      component={ItemAttributeTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-section-type"
      component={ItemSectionTypeListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/item/item-section-type/:id"
      component={ItemSectionTypeEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/action-group"
      component={ActionGroupListPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/system-admin/object/action-group/:id"
      component={ActionGroupEditPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/user-lookup/:userId"
      component={UserManagementEditUserPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/user-lookup/:userId/history"
      component={UserManagementUserHistoryPage}
      allowedRoles={['admin']}
      exact
    />

    <SecuredRoute path="/loading" component={ImpersonateUserTransitionPage} exact />

    {/* ACA */}
    <Redirect from="/aca/:acaId" to="/" exact />

    {/* <SecuredRoute
      path="/aca/:acaId"
      component={AcaDashboardPage}
      allowedRoles={['aca_admin']}
      exact
    /> */}
    <SecuredRoute
      path="/aca/:acaId/activity"
      component={AcaActivityDashboardPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/osp"
      component={AcaPlanDashboardPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/osp/:ospDefId"
      component={OspDefinition}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/org"
      component={AcaOrgSearchPage}
      // component={AcaOrgListPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />

    {/* Workflow Manager for Org */}
    <SecuredRoute
      path="/aca/:acaId/workflow"
      component={OrgWorkflowDashboardPage}
      allowedRoles={['workflow_manager']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/workflow-inactive"
      component={OrgWorkflowDashboardPage}
      allowedRoles={['workflow_manager']}
      exact
    />

    {/* Certification Specialist Dashboard */}
    <Redirect from="/aca/:acaId/specialist" to="/aca/:acaId/specialist/all" exact />
    <SecuredRoute
      path="/aca/:acaId/specialist/:clientFilter"
      component={SpecialistDashboardPage}
      allowedRoles={['certification_specialist']}
      exact
    />

    <Redirect from="/aca/:acaId/settings" to="/aca/:acaId/settings/general" exact />
    <SecuredRoute
      path="/aca/:acaId/settings/general"
      component={AcaSettingsGeneralPage}
      allowedRoles={['aca_admin']}
      exact
    />
    {/* <SecuredRoute
      path="/aca/:acaId/settings/staff"
      component={AcaSettingsStaffPage}
      allowedRoles={['aca_admin']}
      exact
    /> */}
    <SecuredRoute
      path="/aca/:acaId/settings/workflow"
      component={AcaSettingsWorkflowPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/settings/add-client"
      component={AcaSettingsRegisterPage}
      allowedRoles={['aca_admin']}
      exact
    />
    {/* <SecuredRoute
      path="/aca/:acaId/settings/add-inspector"
      component={AcaSettingsRegisterInspectorPage}
      allowedRoles={['aca_admin']}
      exact
    /> */}

    <SecuredRoute
      path="/aca/:acaId/certification-docs"
      component={AcaCertificationDocsPage}
      allowedRoles={['aca_admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/products"
      component={AdminProductSearchPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/reports"
      component={AcaReportsPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/reports/:reportId"
      component={AcaReportPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/aca/:acaId/reports/:reportId/csv"
      component={AcaReportPage}
      allowedRoles={['admin']}
      exact
    />

    {/* Inspection Reports */}
    <Redirect from="/aca/:acaId/inspections" to="/aca/:acaId/inspections/all" exact />

    <SecuredRoute
      path="/aca/:acaId/inspections/all"
      component={InspectionReportAllPage}
      allowedRoles={['admin']}
      exact
    />

    <SecuredRoute
      path="/aca/:acaId/inspections/unannounced"
      component={InspectionReportUnannouncedPage}
      allowedRoles={['admin']}
      exact
    />

    {/* Location Report */}
    <SecuredRoute
      path="/aca/:acaId/locations"
      component={LocationReportPage}
      allowedRoles={['admin']}
      exact
    />

    {/* Inspector */}
    <SecuredRoute
      path="/inspect"
      component={InspectorDashboardPage}
      allowedRoles={['inspector']}
      exact
    />

    {/* Client */}
    <SecuredRoute path="/client" component={ClientDashboardPage} exact />

    {/* Client To-do dashboard */}
    <SecuredRoute
      path="/to-do-list"
      component={TodoListPage}
      blockedRoles={[
        'admin',
        'workflow_manager',
        'certification_specialist',
        'inspector',
      ]}
      exact
    />

    {/* Create Org */}
    <Redirect from="/org/create" to="/org/setup" />
    <SecuredRoute path="/org/setup/:userId" component={OrgSetupPage} exact />
    {/* <SecuredRoute path="/org/setup" component={OrgSetupPage} exact /> */}

    {/* Org */}
    <SecuredRoute path="/org/:orgId" component={OrgDashboardPage} exact />
    <SecuredRoute
      path="/org/:orgId/historical-plans"
      component={OrgHistoricalPlansPage}
      exact
    />
    <Route path="/org/:orgId/legacy-locations" component={LegacyLocationsPage} exact />

    {/* Org Settings */}
    <Redirect from="/org/:orgId/settings" to="/org/:orgId/settings/general" exact />
    <SecuredRoute
      path="/org/:orgId/settings/general"
      component={OrgSettingsGeneralTabPage}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/aca"
      component={OrgSettingsAcaTabPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/inspectors"
      component={OrgSettingsInspectorsTabPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/certifications"
      component={OrgSettingsCertificationsTabPage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/migrate"
      component={OrgSettingsMigratePage}
      allowedRoles={['admin']}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/settings/history"
      component={OrgSettingsHistoryTabPage}
      allowedRoles={['admin']}
      exact
    />

    {/* Org Plan */}
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/checklist"
      component={OrgPlanAppChecklistPage}
      exact
    />

    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/worksheet"
      component={OrgPlanAppWorksheetOverviewPage}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/worksheet/:worksheetId"
      component={OrgPlanAppWorksheetPage}
      exact
    />

    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/location"
      component={OrgPlanAppLocationOverviewPage}
      exact
    />
    <SecuredRoute
      path="/org/:orgId/plan/:planAppId/location/:locationId"
      component={OrgPlanAppLocationPage}
      exact
    />

    {/* Org Certification Docs */}
    <SecuredRoute
      path="/org/:orgId/certification-docs"
      component={OrgCertificationDocsPage}
      exact
    />

    {/* Org Products */}
    <SecuredRoute
      path="/org/:orgId/products"
      component={OrgProductSearchPage}
      allowedRoles={['aca_admin']}
      blockedRoles={['inspector']}
      exact
    />

    {/* Export */}
    <SecuredRoute path="/org/:orgId/export" component={OrgPlanAppExportPage} exact />

    <SecuredRoute path="/org/:orgId/contacts" component={OrgContactsPage} exact />

    <SecuredRoute
      path="/org/:orgId/contacts/:userId"
      component={OrgContactsEditPage}
      exact
    />

    <SecuredRoute path="/org/:orgId/locations" component={OrgLocationsPage} exact />

    {/* Attachments */}
    <SecuredRoute path="/org/:orgId/attachment" component={OrgAttachmentListPage} exact />
    <SecuredRoute
      path="/org/:orgId/attachment/osp"
      component={OrgAttachmentOspListPage}
      exact
    />

    {/* Errors */}
    <Route path="/error/unauthorized" component={ErrorUnauthorizedPage} exact />
    <Route path="/error/forbidden" component={ErrorForbiddenPage} exact />
    <Route path="/error/inactive" component={ErrorInactiveAccountPage} exact />
    <Route path="*" component={ErrorNotFoundPage} />
  </Switch>
);

// Authentication, password reset
const baseAuthRoute = '/auth';
export const loginRoute = () => `${baseAuthRoute}/login`;
export const logoutRoute = () => `${baseAuthRoute}/logout`;
export const requestNewPasswordRoute = () => `${baseAuthRoute}/request-new-password`;
export const requestNewPasswordDoneRoute = () =>
  `${baseAuthRoute}/request-new-password/done`;

export const resetPasswordRoute = resetPasswordHash =>
  `${baseAuthRoute}/reset-password/${resetPasswordHash}`;

// Registration
export const registerRoute = () => `${baseAuthRoute}/register`;
export const registerInspectorRoute = () => `/inspector`;
export const registerDoneRoute = () => `${registerRoute()}/done`;
export const validateMailRoute = emailHash =>
  `${registerRoute()}/validate-mail/${emailHash}`;

// Profile
export const profileRoute = () => '/profile';

// Superadmin
export const adminRoute = () => '/admin';
export const adminCreateAcaRoute = () => `${adminRoute()}/create-aca`;
// Old User Lookup Route
// export const adminUserLookupRoute = () => `${adminRoute()}/user-lookup`;
export const adminUsersLookupRoute = () => `${adminRoute()}/user-lookup`;
export const adminUserEdit = userId => `${adminUsersLookupRoute()}/${userId}`;
export const adminUserHistory = userId => `${adminUserEdit(userId)}/history`;
export const adminNewUser = () => `${adminUsersLookupRoute()}/new`;
export const systemAdminRoute = () => `${adminRoute()}/system-admin`;
export const systemAdminObjectRoute = () => `${systemAdminRoute()}/object`;
export const systemAdminItemRoute = () => `${systemAdminRoute()}/item`;
export const certificationTypeRoute = () =>
  `${systemAdminObjectRoute()}/certification-types`;
export const certificationTypeAddEditRoute = id => `${certificationTypeRoute()}/${id}`;
export const consultantCompaniesRoute = () =>
  `${systemAdminObjectRoute()}/consultant-companies`;
export const consultantCompaniesEditRoute = id =>
  `${systemAdminObjectRoute()}/consultant-companies/${id}`;
export const organizationTypeRoute = () =>
  `${systemAdminObjectRoute()}/organization-type`;
export const organizationTypeEditRoute = id =>
  `${systemAdminObjectRoute()}/organization-type/${id}`;
export const announcementTypeRoute = () =>
  `${systemAdminObjectRoute()}/announcement-type`;
export const announcementTypeEditRoute = id => `${announcementTypeRoute()}/${id}`;
export const accessTypeRoute = () => `${systemAdminObjectRoute()}/access-type`;
export const accessTypeEditRoute = id => `${accessTypeRoute()}/${id}`;
export const announcementsRoute = () => `${systemAdminObjectRoute()}/announcements`;
export const announcementsEditRoute = id => `${announcementsRoute()}/${id}`;
export const announcementHistoryRoute = id => `${announcementsEditRoute(id)}/history`;
export const inspectionYearRoute = () => `${systemAdminObjectRoute()}/inspection-year`;
export const inspectionYearEditRoute = id =>
  `${systemAdminObjectRoute()}/inspection-year/${id}`;
export const priorityTypeRoute = () => `${systemAdminObjectRoute()}/priority-type`;
export const priorityTypeEditRoute = id => `${priorityTypeRoute()}/${id}`;
export const itemRoute = () => `${systemAdminItemRoute()}/item`;
export const itemEditRoute = id => `${itemRoute()}/${id}`;
export const itemTypeRoute = () => `${systemAdminItemRoute()}/item-type`;
export const itemTypeEditRoute = id => `${itemTypeRoute()}/${id}`;
export const itemSubtypeRoute = () => `${systemAdminItemRoute()}/item-subtype`;
export const itemSubtypeEditRoute = id => `${itemSubtypeRoute()}/${id}`;
export const itemVersionRoute = () => `${systemAdminItemRoute()}/item-version`;
export const itemVersionInfoEditRoute = id => `${itemVersionRoute()}/${id}`;
export const itemVersionCreatorRoute = id => `${itemVersionInfoEditRoute(id)}/creator`;
export const itemVersionPicklistRoute = id => `${itemVersionInfoEditRoute(id)}/picklist`;
export const itemVersionHistoryRoute = id => `${itemVersionInfoEditRoute(id)}/history`;
export const itemAttributeTypeRoute = () =>
  `${systemAdminItemRoute()}/item-attribute-type`;
export const itemAttributeTypeEditRoute = id => `${itemAttributeTypeRoute()}/${id}`;
export const itemSectionTypeRoute = () => `${systemAdminItemRoute()}/item-section-type`;
export const itemSectionTypeEditRoute = id => `${itemSectionTypeRoute()}/${id}`;
export const actionGroupRoute = () => `${systemAdminObjectRoute()}/action-group`;
export const actionGroupEditRoute = id => `${actionGroupRoute()}/${id}`;
// Errors
const baseErrorRoute = '/error';
export const errorUnauthorizedRoute = () => `${baseErrorRoute}/unauthorized`;
export const errorForbiddenRoute = () => `${baseErrorRoute}/forbidden`;
export const errorInactiveAccountRoute = () => `${baseErrorRoute}/inactive`;

// ACA Admin
export const acaRoute = acaId => `/aca/${acaId}`;
export const acaDashboardRoute = acaId => `${acaRoute(acaId)}/dashboard`;
export const acaActivityRoute = acaId => `${acaRoute(acaId)}/activity`;
export const acaCertificationDocsRoute = acaId => `${acaRoute(acaId)}/certification-docs`;
export const acaOspBaseRoute = acaId => `${acaRoute(acaId)}/osp`;
export const acaOspDefRoute = (acaId, ospDefId) => `${acaRoute(acaId)}/osp/${ospDefId}`;
export const acaOrgBaseRoute = acaId => `${acaRoute(acaId)}/org`;
export const acaSettingsRoute = acaId => `${acaRoute(acaId)}/settings`;
export const acaReportsRoute = acaId => `${acaRoute(acaId)}/reports`;
export const inspectionReportRoute = acaId => `${acaRoute(acaId)}/inspections`;
export const inspectionReportAllRoute = acaId => `${inspectionReportRoute(acaId)}/all`;
export const inspectionReportUnannouncedRoute = acaId =>
  `${inspectionReportRoute(acaId)}/unannounced`;
export const locationReportRoute = acaId => `${acaRoute(acaId)}/locations`;
export const acaProductsSearchRoute = acaId => `${acaRoute(acaId)}/products`;
export const acaGeneralRoute = orgId => `${acaSettingsRoute(orgId)}/general`;
// export const acaStaffRoute = acaId => `${acaSettingsRoute(acaId)}/staff`;
export const acaAddClientRoute = acaId => `${acaSettingsRoute(acaId)}/add-client`;
// export const acaAddInspectorRoute = acaId => `${acaSettingsRoute(acaId)}/add-inspector`;
export const acaWorkflowRoute = orgId => `${acaSettingsRoute(orgId)}/workflow`;
export const workflowManagerRoute = acaId => `${acaRoute(acaId)}/workflow`;
export const workflowManagerInactiveRoute = acaId =>
  `${acaRoute(acaId)}/workflow-inactive`;
export const certificationSpecialistRoute = acaId => `${acaRoute(acaId)}/specialist`;
export const certificationSpecialistAllClientsRoute = (acaId, clientFilter) =>
  `${certificationSpecialistRoute(acaId)}/${clientFilter}`;

// Inspector
export const inspectorRoute = () => `/inspect`;

// Client
export const clientRoute = () => `/client`;
export const todoListRoute = () => `/to-do-list`;

// Org
export const orgRoute = orgId => `/org/${orgId}`;
export const historicalPlansRoute = orgId => `${orgRoute(orgId)}/historical-plans`;
export const exportRoute = orgId => `${orgRoute(orgId)}/export`;
export const attachmentRoute = orgId => `${orgRoute(orgId)}/attachment`;
export const attachmentOspRoute = orgId => `${attachmentRoute(orgId)}/osp`;
export const certificationDocsRoute = orgId => `${orgRoute(orgId)}/certification-docs`;
export const legacyLocationsRoute = orgId => `${orgRoute(orgId)}/legacy-locations`;
export const orgProductsRoute = orgId => `${orgRoute(orgId)}/products`;
export const orgContactsRoute = orgId => `${orgRoute(orgId)}/contacts`;
export const orgLocationsRoute = orgId => `${orgRoute(orgId)}/locations`;
export const orgContactsEditRoute = (orgId, userId) =>
  `${orgContactsRoute(orgId)}/${userId}`;

// Org Plan
export const planRoute = (orgId, planAppId) => `/org/${orgId}/plan/${planAppId}`;
export const checklistRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/checklist`;
export const worksheetOverviewRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/worksheet`;
export const worksheetRoute = (orgId, planAppId, wsId) =>
  `${planRoute(orgId, planAppId)}/worksheet/${wsId}`;
export const locationOverviewRoute = (orgId, planAppId) =>
  `${planRoute(orgId, planAppId)}/location`;
export const locationRoute = (orgId, planAppId, locationId) =>
  `${planRoute(orgId, planAppId)}/location/${locationId}`;

// Org settings
export const orgSettingsRoute = orgId => `${orgRoute(orgId)}/settings`;
export const orgSettingsAcaRoute = orgId => `${orgSettingsRoute(orgId)}/aca`;
export const orgSettingsGeneralRoute = orgId => `${orgSettingsRoute(orgId)}/general`;
export const orgSettingsInspectorRoute = orgId => `${orgSettingsRoute(orgId)}/inspectors`;
export const orgSettingsCertificationRoute = orgId =>
  `${orgSettingsRoute(orgId)}/certifications`;
export const orgSettingsMigrateRoute = orgId => `${orgSettingsRoute(orgId)}/migrate`;
export const orgSettingsHistoryRoute = orgId => `${orgSettingsRoute(orgId)}/history`;

// Reports
export const reportRoute = (acaId, reportId) => `/aca/${acaId}/reports/${reportId}`;
export const reportCSVRoute = (acaId, reportId) =>
  `/aca/${acaId}/reports/${reportId}/csv`;

// Create org
export const setupOrgRoute = () => '/org/setup';
export const setupOrgForUserRoute = userId => `/org/setup/${userId}`;

export default hot(module)(Routes);
